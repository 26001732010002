const MINOR_VERSION = BRAZIL_PACKAGE_VERSION.replace(/\.\d+\.\d+$/, '');

// Code adapted from: https://code.amazon.com/packages/AmazonUIPageJS/blobs/37dbeea6ca1567f251c49b9f619211a4dc21a108/--/src/_load.js#L133
export function loadCSS(url, metricsId) {
  const element = createCSSElement(url);
  loadElement(element, metricsId);
}

export function loadScript(url: string, metricsId: string) {
  const script = document.createElement('script');
  script.src = url;
  // we want to be able to capture errors from the scripts we load
  script.crossOrigin = 'anonymous';

  loadElement(script, metricsId);
}

/**
 * Determine the asset loader domain by finding its script tag on the page and
 * extracting it from the src attribute.
 */
export function getAssetLoaderDomain(prefix: string): string {
  const scriptTag: HTMLScriptElement = document.querySelector(
    `script[src*="${prefix}"]`
  );

  // Read the <script> tag that injected this file and extract the
  // domain if we can, else default back to prod
  if (scriptTag) {
    return scriptTag.src.split(prefix)[0];
  }
  return 'https://d1uznvntk80v7s.cloudfront.net/';
}

export function loadElement(
  element: HTMLScriptElement | HTMLLinkElement,
  metricsId: string,
  // this is used in the patched webcomponents loader
  onSuccess?: (e: Event) => void
) {
  logCounter(metricsId, 'loaded');
  logCounter(metricsId, `loaded:${MINOR_VERSION}`);
  element.addEventListener('error', evt => {
    logCounter(metricsId, 'error');
    logCounter(metricsId, `error:${MINOR_VERSION}`);

    if (evt) {
      logError(evt);
      evt.stopPropagation?.();
    }
  });
  if (onSuccess) {
    element.addEventListener('load', onSuccess);
  }
  document.head.appendChild(element);
}

function logError(evt: Event) {
  const target = evt.target as HTMLScriptElement | HTMLLinkElement;
  const baseURI = target?.baseURI;
  if (baseURI) {
    const errorMessage = `Error loading component assets for ${JSON.stringify(
      target.outerHTML
    )} from ${baseURI}`;

    if (window.Katal.internalAssetCWErrorLogger) {
      window.Katal.internalAssetCWErrorLogger(errorMessage);
    } else {
      if (!window.Katal.deferredErrorLogs) {
        window.Katal.deferredErrorLogs = [];
      }
      window.Katal.deferredErrorLogs.push(errorMessage);
    }
  }
}

function logCounter(id: string, state: string) {
  const metric = id + 'Asset:' + state;
  if (window.Katal.internalMetricsAssetLogger) {
    window.Katal.internalMetricsAssetLogger(metric, 1);
  } else {
    if (!window.Katal.deferredAssetMetrics) {
      window.Katal.deferredAssetMetrics = {};
    }

    window.Katal.deferredAssetMetrics[metric] = 1;
  }
}

function createCSSElement(url: string) {
  const el = document.createElement('link');
  el.rel = 'stylesheet';
  el.href = url;
  el.className = 'katal-style-api';
  return el;
}
