import { polyfillWebComponents } from './polyfills/webcomponents-loader-internal';
import { loadScript, loadCSS, getAssetLoaderDomain } from './utils/script';

// these CAPITAL_CASE variables are all injected by webpack
/* eslint-disable no-undef */
const configuration = ASSET_CONFIGURATION;
const metricsFilename = METRICS_FILENAME;
const assetLoaderFilenamePrefix = ASSET_LOADER_FILENAME_PREFIX;
/* eslint-enable no-undef */

if (!window.Katal) window.Katal = {};
if (!window.Katal.Performance) window.Katal.Performance = {};

const domain = getAssetLoaderDomain(assetLoaderFilenamePrefix);

// used to tell other parts of our assetloader where to load files from, like
// svg files for flag icons
window.Katal.assetLoaderRoot = domain;

// we have patched the webcomponents-loader to be a function that is called
// with the root url that the polyfill bundles can be loaded from
polyfillWebComponents(`${domain}webcomponents/`);

const cssFilename = domain + configuration.assetFilenames.css;
loadCSS(cssFilename, 'ComponentsCSS');

function writeKatalComponentsScriptTag(es6) {
  const url = domain + configuration.assetFilenames[es6 ? 'es6min' : 'es5min'];
  loadScript(url, 'Components');
}

function supportsNoModule() {
  const script = document.createElement('script');
  return script.noModule !== undefined;
}

// Add metrics to the page
// Should come before components code so the event listener in metrics happens before
const metricsSrc = domain + metricsFilename;
loadScript(metricsSrc, 'KatalInternalMetrics');

window.WebComponents.waitFor(
  () =>
    // in browsers that require polyfills, returning a promise here is an
    // optimization that avoids every `customElements.define` call triggering a
    // full dom scan for custom elements, and instead does a single dom scan once
    // the promise has resolved
    new Promise(resolve => {
      document.addEventListener('katal-ready', resolve);

      // We build our modern bundle based on browsers that support nomodule on
      // script tags
      if (supportsNoModule()) {
        writeKatalComponentsScriptTag(true);
      } else {
        writeKatalComponentsScriptTag(false);
      }
    })
);
